var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.container_id)?_c('TTableSimple',{staticClass:"table-custom",attrs:{"items":_vm.data || [],"fields":_vm.fields,"size":"lg","columnFilter":"","pagination":"","loading":_vm.references_fetching,"options":{ sorter: true }},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TButton',{attrs:{"icon":"cil-eye","tooltip":"View","color":"primary","variant":"outline","size":"sm"},on:{"click":function($event){return _vm.viewReceipt(item)}}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Bill of lading code","value":_vm.lading_bill_id},on:{"update:value":function($event){_vm.lading_bill_id=$event}}})]},proxy:true},{key:"_-filter",fn:function(){return [_c('TButtonClear',{staticClass:"mb-1 ml-2",attrs:{"variant":"outline"},on:{"click":_vm.clearTableFilter}})]},proxy:true},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TLink',{attrs:{"content":item.id,"to":_vm.lodash.getReferenceLink('lading_bill', item.id)}})],1)]}},{key:"customer",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id,"noTranslate":""}})],1)]}},{key:"freight_charge_debited",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.freight_charge_debited,"currency":item.currency_id}})],1)]}},{key:"freight_charge_paid",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.freight_charge_paid,"currency":item.currency_id}})],1)]}},{key:"freight_charge_outstanding",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.freight_charge_outstanding,"currency":item.currency_id}})],1)]}}],null,false,3303947547)}):_vm._e(),(_vm.showDetailVocherModal)?_c('ModalTransactions',{attrs:{"show":_vm.showDetailVocherModal,"voucher":_vm.voucher_selected,"default_filter":{
      'filter[receipts.receiptable_id]': _vm.voucher_selected.id,
      'filter[receipts.receiptable_type]': 'lading_bill',
    }},on:{"update:show":function($event){_vm.showDetailVocherModal=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }